import { act } from 'react'

export const defaultFilter = {
  filterOptions: {},
  pagination: {
    page: 1,
    limit: 10
  }
}
const defaultActions = {
  add: { action: 'add', message: 'Agregar publicidad' },
  update: { action: 'update', message: 'Actualizar publicidad' },
  updateStatus: { action: 'updateStatus', message: 'actualizar' },
  updateCommerceStatus: {
    action: 'updateCommerceStatus',
    message: 'actualizar'
  }
}

const initialState = {
  loading: false,
  data: [],
  selectedData: null,
  filters: defaultFilter,
  nextPageExists: false,
  actions: defaultActions,

  mobilePaidBankAccountsOptions: [],
  selectedMobilePaidBankAccount: null,
  isActiveMobilePaid: true,

  transfersBankAccountsOptions: [],
  selectedTransferBankAccount: null,
  isActiveTransfersBank: true
}

export default initialState
