import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

const prefix = '/recharge-methods'

const RechargeMethodsList = lazy(() => import('@views/recharge-methods/list'))

const RechargeMethodsRoutes = [
  {
    element: <RechargeMethodsList />,
    path: `${prefix}/list`,
    meta: {
      
      actions: ['pagodirecto-level-1'],
      resources: ['Common']
    }
  }
]

export default RechargeMethodsRoutes
