import { apiMethods } from '@services/methods'
import { baseV2URL } from '@configs/apis'

const endpoints = {
  activate: `${baseV2URL}/premium/activate`,
  disable: `${baseV2URL}/superadmin/premium/disable`,
  bundles: `${baseV2URL}/premium/bundles`,
  history: `${baseV2URL}/premium/activated`,
  statistics: `${baseV2URL}/premium/statistics/user`,
  bills: `${baseV2URL}/premium/bills`,
  markBillAsPaid: `${baseV2URL}/premium/bills/update`,
  availableBundles: `${baseV2URL}/premium/bundles/available`
}

export const premiumBundles = async (user_id) => {
  try {
    const url = endpoints.bundles
    const body = {
      user_id
    }
    const { ok, res, error } = await apiMethods.authPostFetch({ url, body })
    return { ok, res, error }
  } catch (error) {
    return error
  }
}

export const premiumActivate = async (body) => {
  try {
    const url = endpoints.activate
    const { ok, res, error } = await apiMethods.authPostFetch({ url, body })
    return { ok, res, error }
  } catch (error) {
    return error
  }
}

export const premiumDisableService = async (body) => {
  try {
    const url = endpoints.disable
    const { ok, res, error } = await apiMethods.authPostFetch({ url, body })
    return { ok, res, error }
  } catch (error) {
    return error
  }
}

export const premiumHistory = async ({
  fromDate: from_date,
  endDate: end_date,
  status = [],
  titlesSelected: bundle_title = [],
  page: skip,
  show: limit,
  building: building_id,
  searchParam: username,
  searchBy
}) => {
  const body = {
    from_date: searchBy === 'date' ? from_date : null,
    end_date: searchBy === 'date' ? end_date : null,
    status,
    username,
    bundle_title,
    building_id,
    skip: skip * (limit - 1),
    limit
  }
  try {
    const url = endpoints.history
    const { ok, res, error } = await apiMethods.authPostFetch({ url, body })

    return { ok, res, error }
  } catch (error) {
    return error
  }
}

export const premiumStatisticCustomer = async (body) => {
  try {
    const url = endpoints.statistics
    const { ok, res, error } = await apiMethods.authPostFetch({ url, body })
    return { ok, res, error }
  } catch (error) {
    return error
  }
}

export const premiumBills = async ({
  fromDate: from_date,
  endDate: end_date,
  page: skip,
  show: limit,
  status,
  commerce: commerce_id,
  building: building_id
}) => {
  const body = {
    from_date,
    end_date,
    skip,
    limit,
    status,
    commerce_id,
    building_id
  }
  try {
    const url = endpoints.bills
    const { ok, res, error } = await apiMethods.authPostFetch({ url, body })
    return { ok, res, error }
  } catch (error) {
    return error
  }
}

export const premiumMarkBillAsPaid = async (body) => {
  try {
    const url = endpoints.markBillAsPaid
    const { ok, res, error } = await apiMethods.authPostFetch({ url, body })
    return { ok, res, error }
  } catch (error) {
    return error
  }
}

export const premiumAvailableBundles = async () => {
  try {
    const url = endpoints.availableBundles
    const { ok, res, error } = await apiMethods.authGetFetch(url)
    return { ok, res, error }
  } catch (error) {
    return error
  }
}
