import toast from 'react-hot-toast'
import { BasicToastContent } from '@src/components/basic-toast-content'
import { errors } from '@src/assets/constants/text'

import {
  setNextPageExists,
  setLoading,
  setTransactions,
  setTransactionTagsOptions,
  setTagsOptions,
  setSupportInfo
} from './slice'

import {
  getTransactionsConstantsService,
  getTransactionsServices,
  getTransactionsServicesAdmin,
  getTransactionsServicesSuperadmin,
  reverseParkingMeterService,
  reverseParkingOvertimeService,
  reverseParkingService,
  reversePremiumService,
  reverseTollService
} from '../../../services/users/transactions'
import { t } from 'i18next'

export const getTransactionsConstants = () => {
  return async (dispatch) => {
    try {
      const { ok, res, error } = await getTransactionsConstantsService()

      if (!ok) throw error

      const { TRANSACTION_TAGS, support_info } = res
      const tags = TRANSACTION_TAGS.map((tag) => {
        return {
          label: tag.name,
          value: tag.value
        }
      })

      const tagsOptions = tags.reduce((acc, curr) => {
        acc[curr.value] = curr
        return acc
      }, {})

      dispatch(setTagsOptions(tagsOptions))
      dispatch(setTransactionTagsOptions(tags))
      dispatch(setSupportInfo(support_info))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    }
  }
}

export const getTransactions = () => {
  return async (dispatch, getState) => {
    try {
      const { isSuperadmin } = getState().superadmin
      dispatch(setLoading(true))

      const filters = getState().transactions.filters
      const { commerce_id, user_id, ...rest } = filters

      const { ok, res, error } = isSuperadmin
        ? await getTransactionsServicesSuperadmin(filters)
        : await getTransactionsServicesAdmin(rest)

      if (!ok) throw error

      const sortTransactions = res?.transactions.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at)
      })

      dispatch(setTransactions(sortTransactions))

      // check if there is a next page
      const nextFilters = { ...filters, skip: filters.skip + 10 }
      const response = await getTransactionsServicesSuperadmin(nextFilters)

      const nextPageExists = response.res.transactions.length > 0
      dispatch(setNextPageExists(nextPageExists))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const reversePremium = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const { selectedTransaction } = getState().transactions

      const body = {
        premium_id: selectedTransaction?.premium_details?.premium_ref
      }

      const { ok, res, error } = await reversePremiumService(body)

      if (!ok) throw error
      toast.success(res.message ?? 'Premium reversado con éxito')

      dispatch(getTransactions())
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const reverseParkingMeter = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const { selectedTransaction } = getState().transactions
      const body = {
        pm_ticket_id: selectedTransaction?.parkingmeter_ticket_ref?._id
      }
      const { ok, res, error } = await reverseParkingMeterService(body)

      if (!ok) throw error
      toast.success(res.message ?? 'Ticket de parquimetro reversado con éxito')

      dispatch(getTransactions())
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const reverseToll = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { selectedTransaction } = getState().transactions

      const body = {
        toll_ticket_id: selectedTransaction?.toll_ticket_ref?._id
      }

      const { ok, res, error } = await reverseTollService(body)

      if (!ok) throw error
      toast.success(res.message ?? 'Ticket de peaje reversado con éxito')

      dispatch(getTransactions())
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const reverseParkingOvertime = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { selectedTransaction } = getState().transactions

      const body = {
        transaction_id: selectedTransaction?.ticket_ref?._id
      }

      const { ok, res, error } = await reverseParkingOvertimeService(body)

      if (!ok) throw error
      toast.success(res.message ?? 'Ticket reversado con éxito')

      dispatch(getTransactions())
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const reverseParking = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { selectedTransaction } = getState().transactions

      const body = {
        ticket_id: selectedTransaction?.ticket_ref?._id,
        paid_by: selectedTransaction?.ticket_ref?.paid_by,
        has_premium:
          selectedTransaction?.ticket_ref?.regular_premium_earning_counted
      }

      const { ok, res, error } = await reverseParkingService(body)

      if (!ok) throw error
      toast.success(res.message ?? 'Ticket reversado con éxito')

      dispatch(getTransactions())
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}
