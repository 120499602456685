import { apiMethods } from '@services/methods'
import { couponsURL, baseV2URL, micropd03, baseV1URL } from '@configs/apis'

const endpoints = {
  updateService: `${baseV1URL}/constants/support-info`
}

export const updateSupportConstantsService = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.updateService,
      body
    })
    return response
  } catch (error) {
    return error
  }
}
