import { createSlice } from '@reduxjs/toolkit'
import initialState from './initialState'

export const slice = createSlice({
  name: 'rechargeMethods',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setData: (state, action) => {
      state.data = action.payload
    },
    setRechargeMethodsFilters: (state, action) => {
      state.filters = action.payload
    },
    setNextPageExists: (state, action) => {
      state.nextPageExists = action.payload
    },
    setMobilePaidBankAccountsOptions: (state, action) => {
      state.mobilePaidBankAccountsOptions = action.payload
    },
    setTransfersBankAccountsOptions: (state, action) => {
      state.transfersBankAccountsOptions = action.payload
    },
    setSelectedMobilePaidBankAccount: (state, action) => {
      state.selectedMobilePaidBankAccount = action.payload
    },
    setSelectedTransferBankAccount: (state, action) => {
      state.selectedTransferBankAccount = action.payload
    },
    setSelectedData: (state, action) => {
      state.selectedData = action.payload
    },
    setIsActiveMobilePaid: (state, action) => {
      state.isActiveMobilePaid = action.payload
    },
    setIsActiveTransfersBank: (state, action) => {
      state.isActiveTransfersBank = action.payload
    }
  }
})

export const {
  setLoading,
  setRechargeMethodsFilters,
  setData,
  setNextPageExists,
  setSelectedData,
  setMobilePaidBankAccountsOptions,
  setTransfersBankAccountsOptions,
  setSelectedMobilePaidBankAccount,
  setSelectedTransferBankAccount,
  setIsActiveMobilePaid,
  setIsActiveTransfersBank
} = slice.actions

export const rechargeMethods = slice.reducer
