import { apiMethods } from '@services/methods'
import { baseV1URL, baseV2URL, micropd02 } from '@configs/apis'

const urlBase = `${baseV1URL}`

const endpoints = {
  getRechargeMethods: `${micropd02}/superadmin/recharge-methods/list`,
  updateRechargeMethods: `${micropd02}/superadmin/recharge-methods/update`
}

export const getRechargeMethods = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.getRechargeMethods,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const updateRechargeMethodStatus = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.updateRechargeMethods,
      body
    })
    return response
  } catch (error) {
    return error
  }
}
