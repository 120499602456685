import {
  setAmountsLoading,
  setDashboardLoading,
  setDashboardData,
  setAmounts
} from '@store/commerces'
import { commerceAmounts, commerceDashboard } from '@services/commerces'
import { errors } from '@src/assets/constants/text'

import { formatterTransactions, getDates, getCommerceData } from '@utils'
import toast from 'react-hot-toast'
import { BasicToastContent } from '@src/components/basic-toast-content'
import moment from 'moment'
import {
  addCommerceService,
  addCommercesExtensionService,
  addCommercesExtensionSuperadminService,
  assignAssociatesCommerceService,
  blockCommerceService,
  checkIfCommerceMobilePaymentWasAssignedToCustomerService,
  commerceListAutocomplete,
  commerceTypesAutocomplete,
  debitUserFundsToCreditCommerceService,
  deleteCommerceExtensionService,
  deleteCommerceExtensionSuperadminService,
  enableCommerceToUseExtensionsServices,
  exportCommercesExtensionsServices,
  exportCommercesExtensionsSuperadminServices,
  getAssociatesCommerceMetricsService,
  getAssociatesCommerceService,
  getCommerceRolesService,
  getCommercesListService,
  getCommercesService,
  getExtensionsService,
  getExtensionsSuperadminService,
  getPatientInfoService,
  notifyCommerceBankTransferService,
  notifyCommerceMobilePaymentService,
  rechargeCommerceFundsServices,
  updateCommerceExtensionService,
  updateCommerceExtensionSuperadminService,
  updateCommerceService,
  uploadFileExtensionsServices,
  uploadFileExtensionsSuperadminServices
} from '../../services/commerces'
import {
  setCommercesOptions,
  setCommercesTypesOptions,
  setData,
  setLoading,
  setSelectedCommerceInfo,
  setNextPageExists,
  setSelectedCommerceExtension,
  defaultExtensionsFilters,
  setCommercesExtensionsFilters,
  setCommerceAssociatesOptions,
  setTemporalXAxis,
  setTemporalYAxisTotal,
  setTemporalYAxisAmount,
  setTotalAmount,
  setTotalQty,
  setTotalCommercesAmount,
  setCurrentCommerce,
  setCommerceMobilePaid,
  setSelectedCommerceRoles
} from './'
import {
  setCommercesBancamigaBankOptions,
  setCommercesBankOptions,
  setCommercesOtherBankOptions
} from '../wallet/bank-transfers/slice'
import {
  getAssociateCommercesTransactionService,
  sendMassiveTransferService
} from '../../services/users/transactions'
import { setTransactions } from '../users/transactions'
import { t } from 'i18next'

export const getCommerceRoles = (id) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true))
      const body = {
        commerce_id: id
      }
      const { ok, res, error } = await getCommerceRolesService(body)
      if (!ok) throw error
      const rolesOptions = res?.data?.items?.map((item) => ({
        value: item._id,
        label: item.name
      }))
      dispatch(setSelectedCommerceRoles(rolesOptions))
    } catch (error) {
      toast.error(error?.description ?? error.message ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const getCurrentCommerce = () => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true))
      const currentCommerce = getCommerceData()
      dispatch(setCurrentCommerce(currentCommerce))
    } catch (error) {
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const checkIfCommerceMobilePaymentWasAssignedToCustomer = () => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true))
    const { commerceMobilePaid } = getState().commerces
    const body = {
      phone: commerceMobilePaid.phone_issuer,
      pm_reference: commerceMobilePaid.reference,
      amount: commerceMobilePaid.amount
    }

    try {
      const { ok, res, error } =
        await checkIfCommerceMobilePaymentWasAssignedToCustomerService(body)

      if (!ok) throw error
    } catch (error) {
      toast.error(error?.description ?? error.message ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}
export const debitUserFundsToCreditCommerce = (otp) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true))
    try {
      const { commerceMobilePaid } = getState().commerces
      const body = {
        otp_code: otp,
        phone: commerceMobilePaid.phone_issuer,
        pm_reference: commerceMobilePaid.reference,
        amount: commerceMobilePaid.amount
      }
      const res = await debitUserFundsToCreditCommerceService(body)
      if (!res.ok) throw res.error
      toast.success(res.message ?? 'Fondos asignados correctamente')
    } catch (error) {
      console.log('an error occurred', error)
      toast.error(error?.description ?? error.message ?? errors.GENERAL_ERR)
      throw error
    } finally {
      dispatch(setLoading(false))
    }
  }
}

// NOTIFY MOBILE PAYMENT
export const notifyCommerceMobilePayment = (body) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      dispatch(setCommerceMobilePaid(body))

      const { ok, res, error } = await notifyCommerceMobilePaymentService(body)

      if (!ok) throw error

      const { data } = res
      if (data?.status === 'ASSIGNED_TO_OTHER_USER') {
        dispatch(checkIfCommerceMobilePaymentWasAssignedToCustomer())
        throw res
      }

      toast.success(res.message ?? 'Recarga notificada correctamente')
    } catch (error) {
      toast.error(error?.description ?? error.message ?? errors.GENERAL_ERR)
      throw error
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const notifyCommerceBankTransfer = (body) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      dispatch(setCommerceMobilePaid(body))

      const { ok, res, error } = await notifyCommerceBankTransferService(body)

      if (!ok) throw error

      toast.success(res.message ?? 'Recarga notificada correctamente')
    } catch (error) {
      toast.error(error?.description ?? error.message ?? errors.GENERAL_ERR)
      throw error
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const sendMassiveTransfer = (body) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { ok, res, error } = await sendMassiveTransferService(body)
      if (!ok) throw error
      toast.success(res.message ?? 'Transferencia masiva enviada correctamente')
    } catch (error) {
      toast.error(error?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const getAssociateCommercesTransaction = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const { filtersCommercesAssociate: filters } = getState().commerces

      const { ok, res, error } = await getAssociateCommercesTransactionService(
        filters
      )

      console.log(res)
      if (!ok) throw error
      dispatch(setTransactions(res?.data?.result ?? []))
      dispatch(setNextPageExists(res?.data?.nextPageExist ?? false))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const getCommercesBanks = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { filters } = getState().commerces

      const { ok, res, error } = await getCommercesListService(filters)

      const commercesBankOptions = res?.commerces?.map(
        ({ _id, name, bank_accounts }) => ({
          value: _id,
          label: name,
          bank_accounts
        })
      )
      const { filteredCommercesBankOptions, otherCommercesBankOptions } =
        commercesBankOptions.reduce(
          (acc, { bank_accounts, ...item }) => {
            const targetArray = bank_accounts.some(
              ({ bank_code }) => bank_code === '0172'
            )
              ? acc.filteredCommercesBankOptions
              : acc.otherCommercesBankOptions
            targetArray.push({ ...item, bank_accounts })
            return acc
          },
          { filteredCommercesBankOptions: [], otherCommercesBankOptions: [] }
        )

      dispatch(setCommercesBancamigaBankOptions(filteredCommercesBankOptions))
      dispatch(setCommercesOtherBankOptions(otherCommercesBankOptions))
      dispatch(setCommercesBankOptions(commercesBankOptions))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const getCommerces = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { filters } = getState().commerces

      const newFilter = {
        pagination: filters.pagination,
        filterOptions: filters.filterOptions
      }

      const { ok, res, error } = await getCommercesService(newFilter)
      if (!ok) throw error

      dispatch(setData(res?.data?.items ?? []))
      dispatch(setNextPageExists(res?.data?.nextPageExists ?? false))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const enableCommerceToUseExtensions = (body) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const { selectedCommerceInfo } = getState().commerces

      const sendBody = {
        commerce_list: body,
        commerce_id: selectedCommerceInfo._id
      }

      const { ok, res, error } = await enableCommerceToUseExtensionsServices(
        sendBody
      )

      toast.success('Comercios habilitados correctamente')

      if (!ok) throw error
      dispatch(getCommerces())
      dispatch(setSelectedCommerceInfo(res?.data?.commerce))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const getCommercesExtensions = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const { isSuperadmin } = getState().superadmin
      const { filtersExtensions } = getState().commerces

      // TODO: Chage this to getCommerceData() when superadmin is ready
      if (!isSuperadmin) {
        dispatch(setSelectedCommerceInfo(getCommerceData()))
      }

      const { ok, res, error } = isSuperadmin
        ? await getExtensionsSuperadminService(filtersExtensions)
        : await getExtensionsService(filtersExtensions)

      if (!ok) throw error

      dispatch(setData(res?.items ?? []))
      dispatch(setNextPageExists(res?.nextPage ?? false))
    } catch (err) {
      // toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const updateCommerceExtensionStatus = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const { isSuperadmin } = getState().superadmin
      const { selectedCommerceExtension, selectedCommerceInfo } =
        getState().commerces

      const sendBody = {
        commerce_id: selectedCommerceInfo._id,
        extension_id: selectedCommerceExtension._id,
        status:
          selectedCommerceExtension.status === 'active' ? 'inactive' : 'active'
      }

      const { ok, res, error } = isSuperadmin
        ? await updateCommerceExtensionSuperadminService(sendBody)
        : await updateCommerceExtensionService(sendBody)

      if (!ok) throw error
      dispatch(getCommercesExtensions())
      dispatch(setSelectedCommerceExtension(res.data.extension))
      toast.success(res.message ?? 'Extensión editada correctamente')
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const updateCommerceExtension = (body) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { isSuperadmin } = getState().superadmin
      const { selectedCommerceExtension, selectedCommerceInfo } =
        getState().commerces

      const sendBody = {
        ...body,
        commerce_id: selectedCommerceInfo._id,
        extension_id: selectedCommerceExtension._id
      }

      const { ok, res, error } = isSuperadmin
        ? await updateCommerceExtensionSuperadminService(sendBody)
        : await updateCommerceExtensionService(sendBody)

      if (!ok) throw error
      dispatch(getCommercesExtensions())
      dispatch(setSelectedCommerceExtension(res.data.extension))
      toast.success(res.message ?? 'Extensión editada correctamente')
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const deleteCommerceExtension = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const { isSuperadmin } = getState().superadmin
      const { selectedCommerceExtension, selectedCommerceInfo } =
        getState().commerces

      const sendBody = {
        commerce_id: selectedCommerceInfo._id,
        extension_id: selectedCommerceExtension._id
      }

      const { ok, res, error } = isSuperadmin
        ? await deleteCommerceExtensionSuperadminService(sendBody)
        : await deleteCommerceExtensionService(sendBody)

      if (!ok) throw error
      toast.success(res.message ?? 'Extensión eliminada correctamente')

      dispatch(getCommercesExtensions())
      dispatch(setSelectedCommerceExtension(null))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const addCommercesExtensionAlcaldia = (body) => {
  return async (dispatch, getState) => {
    try {
      const { isSuperadmin } = getState().superadmin
      const { selectedCommerceInfo, commercesOptions } = getState().commerces

      const sendBody = {
        ...body,
        funds: 0
      }

      const { ok, res, error } = isSuperadmin
        ? await addCommercesExtensionSuperadminService(sendBody)
        : await addCommercesExtensionService(sendBody)

      if (!ok) throw error

      toast.success(res.message ?? 'Extensión cargada correctamente')
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const addCommercesExtension = (body) => {
  return async (dispatch, getState) => {
    try {
      const { isSuperadmin } = getState().superadmin
      const { selectedCommerceInfo, commercesOptions } = getState().commerces

      const sendBody = {
        ...body,
        commerce_id: selectedCommerceInfo._id
      }

      const { ok, res, error } = isSuperadmin
        ? await addCommercesExtensionSuperadminService(sendBody)
        : await addCommercesExtensionService(sendBody)

      if (!ok) throw error

      toast.success(res.message ?? 'Extensión cargada correctamente')

      const filters = {
        ...defaultExtensionsFilters,
        commerce_id: selectedCommerceInfo._id
      }
      dispatch(setCommercesExtensionsFilters(filters))
      dispatch(getCommercesExtensions())
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const uploadFileExtensions = (body) => {
  return async (dispatch, getState) => {
    try {
      const { isSuperadmin } = getState().superadmin
      dispatch(setLoading(true))

      const { ok, res, error } = isSuperadmin
        ? await uploadFileExtensionsSuperadminServices(body)
        : await uploadFileExtensionsServices(body)

      if (!ok) throw error
      toast.success(res.message ?? 'Archivo cargado correctamente')

      dispatch(getCommercesExtensions())
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const exportCommercesExtensions = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const { selectedCommerceInfo } = getState().commerces
      const { isSuperadmin } = getState().superadmin
      const commerceId = selectedCommerceInfo?._id

      const { ok, res, error } = isSuperadmin
        ? await exportCommercesExtensionsSuperadminServices(commerceId)
        : await exportCommercesExtensionsServices(commerceId)

      const link = document.createElement('a')
      link.href = res?.data?.excelFile
      link.download = 'archivo'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      if (!ok) throw error
      toast.success('Archivo generado correctamente')
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const updateCommerce = (body) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const { selectedCommerceInfo } = getState().commerces

      const sendBody = {
        commerce_id: selectedCommerceInfo._id,
        ...body
      }
      const { ok, res, error } = await updateCommerceService(sendBody)

      if (!ok) throw error
      toast.success(res?.data?.message ?? 'Comercio editado correctamente')

      dispatch(setSelectedCommerceInfo(res?.data?.commerce ?? null))
      dispatch(getCommerces())
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const addCommerce = (body) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const { ok, res, error } = await addCommerceService(body)

      if (!ok) throw error
      toast.success(res.message ?? 'Comercio creado correctamente')

      dispatch(setSelectedCommerceInfo(res?.user ?? null))
      dispatch(getCommerces())
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const getCommercesTypeOptions = () => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true))

      const { ok, res, error } = await commerceTypesAutocomplete()

      if (!ok) throw error

      const commerceTypeOptions = res.categoryList.map((item) => ({
        value: item,
        label: item
      }))

      dispatch(setCommercesTypesOptions(commerceTypeOptions))
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const autocompleteCommerceList = (body) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true))
      const body = {
        input: ''
      }
      const { ok, res, error } = await commerceListAutocomplete(body)

      if (!ok) throw error

      let commerceOptions = res.commerces.map((item) => ({
        value: item._id,
        label: item.name,
        data: item
      }))

      // Sort commerceOptions alphabetically by label
      commerceOptions = commerceOptions.sort((a, b) =>
        a.label.localeCompare(b.label)
      )

      dispatch(setCommercesOptions(commerceOptions))
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const getCommerceAmounts = () => {
  return async (dispatch) => {
    try {
      dispatch(setAmountsLoading(true))
      const { ok, res, error } = await commerceAmounts()
      if (!ok) throw error
      //dispatch(setAmounts(res.user_amounts[0]))
    } catch (error) {
      toast((t) => (
        <BasicToastContent
          t={t}
          message={error?.description || 'Ocurrió un error intente más tarde'}
          type={'danger'}
        />
      ))
    } finally {
      dispatch(setAmountsLoading(false))
    }
  }
}

export const getCommerceDashboard = () => {
  return async (dispatch, geState) => {
    try {
      const { dashboardForm } = geState().commerces
      dispatch(setDashboardLoading(true))
      const { ok, res, error } = await commerceDashboard(dashboardForm)
      if (!ok) throw error
      const { transactions_bs: rawTransactions } = res
      const dashboardDataRaw = rawTransactions.map((item) => ({
        ...item,
        date: moment(item.date).format('ll')
      }))

      if (dashboardForm.period === 'daily' && dashboardDataRaw.length > 0) {
        const datesAxis = getDates('months').map((item) =>
          moment(item).format('ll')
        )

        const dashboardData = datesAxis.map((item) => {
          const found = dashboardDataRaw.find((i) => i.date === item)
          return found ? found : { date: item, qty: 0, amount: 0 }
        })
        dispatch(setDashboardData(dashboardData))
      } else {
        dispatch(setDashboardData(dashboardDataRaw))
      }
      dispatch(setAmounts(formatterTransactions(rawTransactions)))
    } catch (error) {
      console.log({ error })
      toast((t) => (
        <BasicToastContent
          t={t}
          message={error?.description || 'Ocurrió un error intente más tarde'}
          type={'danger'}
        />
      ))
    } finally {
      dispatch(setDashboardLoading(false))
    }
  }
}

export const blockCommerces = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { selectedCommerceInfo } = getState().commerces

      const body = {
        id: selectedCommerceInfo._id,
        blocked: selectedCommerceInfo.status === 'active'
      }

      const { ok, res, error } = await blockCommerceService(body)

      if (!ok) throw error
      toast.success(res.message ?? 'Comercio bloqueado correctamente')
      dispatch(getCommerces())
      dispatch(setSelectedCommerceInfo(res.commerce))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const enableCommerceExtensions = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { selectedCommerceInfo } = getState().commerces

      const body = {
        commerce_id: selectedCommerceInfo._id,
        is_extensions_enabled: !selectedCommerceInfo.is_extensions_enabled
      }

      const { ok, res, error } = await updateCommerceService(body)

      if (!ok) throw error
      toast.success(res.message ?? 'Extensiones habilitadas correctamente')
      dispatch(getCommerces())
      dispatch(setSelectedCommerceInfo(res.commerce))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const rechargeCommerceFunds = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { selectedCommerceInfo } = getState().commerces
      const { selectedRecharge } = getState().customers

      const finalAmount = Number(
        selectedRecharge?.amount.replace(/\./g, '').replace(/,/g, '.')
      )

      const body = {
        ...selectedRecharge,
        amount: finalAmount,
        commerce: selectedCommerceInfo._id
      }

      const { ok, res, error } = await rechargeCommerceFundsServices(body)

      if (!ok) throw error
      toast.success(res.message ?? 'Extensiones habilitadas correctamente')
      dispatch(getCommerces())
      dispatch(setSelectedCommerceInfo(res.commerce))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const assignAssociatesCommerce = (body) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { selectedCommerceInfo } = getState().commerces

      const commerceObjectList = body.map((item) => ({
        commerce_id: item.commerce_id,
        withdraw_fee: item.percentage
      }))

      const sendBody = {
        commerce_id: selectedCommerceInfo?._id,
        commerce_object_list: commerceObjectList
      }

      const { ok, res, error } = await assignAssociatesCommerceService(sendBody)

      if (!ok) throw error
      toast.success('Comercios habilitados correctamente')
      dispatch(getCommerces())
      dispatch(setSelectedCommerceInfo(res?.data?.commerce))
    } catch (error) {
      toast.error(
        err?.description ?? errors.GENERAL_ERR ?? 'Error al asignar comercios'
      )
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const getAssociatesCommerce = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const { ok, res, error } = await getAssociatesCommerceService()

      console.log(res?.data?.commerce_associates)
      const associatedCommercesOptions = res?.data?.commerce_associates.map(
        (item) => ({
          value: item.commerce_id,
          label: item.commerce_name
        })
      )

      if (!ok) throw error
      dispatch(setCommerceAssociatesOptions(associatedCommercesOptions))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const getAssociatesCommerceMetrics = (data) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      if (!data) return

      const { ok, res, error } = await getAssociatesCommerceMetricsService(data)
      console.log({ res })

      if (!ok) throw error

      const dates = []
      const transactionAmounts = []
      const transactionQuantities = []
      const transactionCommercesAmounts = []

      res.data.result.forEach((item) => {
        dates.push(item.date)
        transactionAmounts.push(item.transactionAmount)
        transactionQuantities.push(item.operations)
        transactionCommercesAmounts.push(item.feeParentCollected)
      })

      const totalTransactionAmounts = transactionAmounts.reduce(
        (acc, amount) => acc + amount,
        0
      )
      const totalTransactionQuantities = transactionQuantities.reduce(
        (acc, quantity) => acc + quantity,
        0
      )
      const totalTransactionCommercesAmounts =
        transactionCommercesAmounts.reduce((acc, amount) => acc + amount, 0)

      dispatch(setTemporalXAxis(dates))
      dispatch(setTemporalYAxisTotal(transactionQuantities))
      dispatch(setTemporalYAxisAmount(transactionAmounts))
      dispatch(setTotalAmount(totalTransactionAmounts))
      dispatch(setTotalCommercesAmount(totalTransactionCommercesAmounts))
      dispatch(setTotalQty(totalTransactionQuantities))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}
