import toast from 'react-hot-toast'
import * as service from './recharge-methods-service'
import {
  setData,
  setIsActiveMobilePaid,
  setIsActiveTransfersBank,
  setLoading,
  setMobilePaidBankAccountsOptions,
  setNextPageExists,
  setSelectedData,
  setSelectedMobilePaidBankAccount,
  setSelectedTransferBankAccount,
  setTransfersBankAccountsOptions
} from './slice'
import { errors } from '@src/assets/constants/text'

const getBankAccountsOptions = (type, data) => {
  const selectedRecharge =
    data?.items?.find((item) => item?.type === type) || {}

  const isActive = selectedRecharge?.commerce_active

  const bankAccounts = selectedRecharge?.bank_accounts || []
  const activeBankAccounts = bankAccounts
    .filter((item) => item?.active)
    .map((item) => ({
      label: item?.bank_name,
      value: item
    }))

  return {
    isActive,
    activeBankAccounts
  }
}

export const getRechargeMethods = () => {
  return async (dispatch, getState) => {
    try {
      const { filters } = getState().rechargeMethods
      dispatch(setLoading(true))

      const { ok, res, error } = await service.getRechargeMethods(filters)

      if (!ok) throw error
      const { data } = res

      const {
        isActive: isActiveMobilePaid,
        activeBankAccounts: mobilePaidBankAccountsOptions
      } = getBankAccountsOptions('pago_movil', data)
      const {
        isActive: isActiveTransfersBank,
        activeBankAccounts: transfersBankAccountsOptions
      } = getBankAccountsOptions('transferencias', data)

      dispatch(setIsActiveMobilePaid(isActiveMobilePaid))
      dispatch(setIsActiveTransfersBank(isActiveTransfersBank))

      dispatch(setData(data?.items))
      dispatch(setNextPageExists(data?.nextPageExists))

      dispatch(setMobilePaidBankAccountsOptions(mobilePaidBankAccountsOptions))
      dispatch(setTransfersBankAccountsOptions(transfersBankAccountsOptions))

      dispatch(
        setSelectedMobilePaidBankAccount(mobilePaidBankAccountsOptions[0].value)
      )
      dispatch(
        setSelectedTransferBankAccount(transfersBankAccountsOptions[0].value)
      )
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const updateCustomerRechargeMethodStatus = () => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true))
    try {
      const { selectedData } = getState().rechargeMethods
      const { active } = selectedData

      const { ok, res, error } = await service.updateRechargeMethodStatus({
        ...selectedData,
        active: !active
      })

      if (!ok) throw error

      toast.success('Estado actualizado correctamente')
      dispatch(getRechargeMethods())
      dispatch(setSelectedData(res?.data ?? null))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const updateCommerceRechargeMethodStatus = () => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true))
    try {
      const { selectedData } = getState().rechargeMethods
      const { commerce_active } = selectedData

      const { ok, res, error } = await service.updateRechargeMethodStatus({
        ...selectedData,
        commerce_active: !commerce_active
      })

      if (!ok) throw error
      toast.success('Estado actualizado correctamente')
      dispatch(getRechargeMethods())
      dispatch(setSelectedData(res?.data ?? null))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}
