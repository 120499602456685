import { apiMethods } from '@services/methods'
import {
  couponsURL,
  baseV2URL,
  baseV1URL,
  authenticationURL
} from '@configs/apis'

const endpoints = {
  addPdUser: `${baseV1URL}/commerces/user`,
  getPdRoles: `${baseV1URL}/commerces/role`,
  getPdUsers: `${baseV1URL}/commerces/users`,
  getCommerceWorkTeam: `${authenticationURL}/superadmin/operational-users/list/commerce`,
  resetCommerceUser: `${baseV2URL}/superadmin/commerces/reset-password`,
  updateAbility: `${authenticationURL}/superadmin/operational-users/assign-ability`,
  addOperationalUserToCommerce: `${authenticationURL}/superadmin/operational-users/add/commerce`
}

export const addOperationalUserToCommerceService = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.addOperationalUserToCommerce,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const updateAbilityService = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.updateAbility,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const resetCommerceUserService = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.resetCommerceUser,
      body
    })
    return response
  } catch (error) {
    return error
  }
}
export const getCommerceWorkTeamService = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.getCommerceWorkTeam,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const addPdUserServices = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.addPdUser,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const getPdUsersService = async (body) => {
  try {
    const response = await apiMethods.authGetFetch(endpoints.getPdUsers)
    return response
  } catch (error) {
    return error
  }
}
export const getPdRolesServices = async (body) => {
  try {
    const response = await apiMethods.authGetFetch(endpoints.getPdRoles)
    return response
  } catch (error) {
    return error
  }
}
