import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './initialState'

export const superadminSlice = createSlice({
  name: 'superadmin',
  initialState,
  reducers: {
    setNavbarOptions: (state, action) => {
      state.navbarOptions = action.payload
    },
    setSidebarOptions: (state, action) => {
      state.sidebarOptions = action.payload
    },
    setIsSuperadmin: (state, action) => {
      state.isSuperadmin = action.payload
    },
    setSupportInfo: (state, action) => {
      state.supportInfo = action.payload
    }
  },
  extraReducers: (builder) => {}
})

export const {
  setSupportInfo,
  setNavbarOptions,
  setSidebarOptions,
  setIsSuperadmin
} = superadminSlice.actions

export const superadmin = superadminSlice.reducer
