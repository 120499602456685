import toast from 'react-hot-toast'
import { BasicToastContent } from '@src/components/basic-toast-content'
import { errors } from '@src/assets/constants/text'

import { setSupportInfo } from './slice'
import { getTransactionsConstantsService } from '../../services/users/transactions'
import { updateSupportConstantsService } from '../../services/superadmin'

export const getConstants = () => {
  return async (dispatch) => {
    try {
      const { ok, res, error } = await getTransactionsConstantsService()
      if (!ok) throw error
      const { support_info } = res
      dispatch(setSupportInfo(support_info))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    }
  }
}

export const updateSupportConstants = (data) => {
  return async (dispatch) => {
    try {
      const body = {
        email: data.email,
        office_numbers: [data.office_number_1, data.office_number_2],
        whatsapp_number: data.whatsapp_number,
        attention_hours: {
          days: '',
          hours: ''
        }
      }
      const { ok, res, error } = await updateSupportConstantsService({
        support_info: {
          ...body
        }
      })

      if (!ok) throw error
      toast.success('Información actualizada correctamente')
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    }
  }
}
