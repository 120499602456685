import {
  setBundles,
  setLoading,
  setLoadingDetails,
  setInvoicesLoading,
  setActivationLoading,
  setBundlesTitles,
  setTitlesSelected,
  setPagination,
  setBundlesHistory,
  setSelectedHistoryBundle,
  setBundlesInvoicesHistory
} from '@store/parking-lot/bundles'
import {
  premiumActivate,
  premiumDisableService,
  premiumHistory,
  premiumStatisticCustomer,
  premiumBills,
  premiumBundles,
  premiumAvailableBundles
} from '@src/services/parking-lot/bundles'

import toast from 'react-hot-toast'
import { BasicToastContent } from '@src/components/basic-toast-content'
import { errors } from '@src/assets/constants/text'

export const fetchCustomerBundles = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { selectedCustomer } = getState().customers
      const { ok, res, error } = await premiumBundles(selectedCustomer._id)
      if (!ok) throw error
      dispatch(setBundles(res.premium_bundles))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const bundleActivation = (daysQty) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setActivationLoading(true))
      const { plBundles, customers } = getState()
      const { selectedBundle } = plBundles
      const { selectedCustomer } = customers

      const { ok, error } = await premiumActivate({
        user_id: selectedCustomer?._id,
        buildings: selectedBundle?.buildings,
        qty_days:
          selectedBundle?.premium_type !== 'regular' ? parseInt(daysQty) : null,
        premium_type: selectedBundle?.premium_type,
        conditions: selectedBundle?.conditions,
        bundle_title: selectedBundle?.title
      })
      if (!ok) throw error
      toast.success('Paquete activado con éxito')
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setActivationLoading(false))
    }
  }
}

export const bundlesHistory = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { plBundles } = getState()
      const {
        pagination,
        dateRange: dates,
        searchParam,
        searchBy,
        building,
        status,
        titlesSelected,
        bundlesTitles
      } = plBundles

      let titlesToPass = titlesSelected

      if (titlesSelected.includes('todos')) {
        titlesToPass = bundlesTitles.map((title) => title.value)
      }

      const { ok, res, error } = await premiumHistory({
        ...dates,
        ...pagination,
        searchBy,
        building,
        status,
        searchParam,
        titlesSelected: titlesToPass
      })

      if (!ok) throw error
      const nextPageExists = res?.premiums?.length >= pagination.show
      dispatch(setPagination({ ...pagination, nextPageExists }))
      dispatch(setBundlesHistory(res?.premiums ?? []))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const getAvailableBundles = () => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true))
      const { ok, res, error } = await premiumAvailableBundles()
      if (!ok) throw error
      const bundlesTitles = res?.premium_bundles?.map((bundle) => ({
        value: bundle,
        label: bundle,
        isFixed: true,
        color: '#365af6'
      }))
      dispatch(setTitlesSelected(res?.premium_bundles ?? []))
      dispatch(setBundlesTitles(bundlesTitles))
      dispatch(bundlesHistory())
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const getCustomerStatistics = (selectedBundle) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoadingDetails(true))
      const { dateRange: dates } = getState().plBundles

      const date_from = selectedBundle?.start_date ?? dates.fromDate
      const date_to = selectedBundle?.expire_date ?? dates.endDate

      const { ok, res, error } = await premiumStatisticCustomer({
        date_from,
        date_to,
        premium_type: selectedBundle?.premium_type,
        user_id: selectedBundle?.customer?._id,
        building_code: selectedBundle?.building_code
      })

      if (!ok) throw error
      dispatch(setSelectedHistoryBundle({ ...selectedBundle, ...res }))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoadingDetails(false))
    }
  }
}

export const getInvoices = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setInvoicesLoading(true))
      const { plBundles } = getState()
      const { pagination, dateRange, statusInvoices, commerce } = plBundles

      const { ok, res, error } = await premiumBills({
        ...dateRange,
        ...pagination,
        status: [...statusInvoices.map((status) => status?.value)],
        commerce: commerce._id
      })

      if (!ok) throw error
      const nextPageExists = res?.bills?.length >= pagination.show
      dispatch(setPagination({ ...pagination, nextPageExists }))
      dispatch(setBundlesInvoicesHistory(res?.bills ?? []))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setInvoicesLoading(false))
    }
  }
}

export const premiumDisable = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { plBundles, customers } = getState()
      const { selectedBundle } = plBundles
      const { selectedCustomer } = customers

      const { ok, res, error } = await premiumDisableService({
        user_id: selectedCustomer?._id,
        type: selectedBundle?.premium_type,
        bundle_title: selectedBundle?.title
      })
      if (!ok) throw error
      toast.success('Paquete desactivado con éxito')
      dispatch(fetchCustomerBundles())
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}
